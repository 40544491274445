import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MxvGautocompleteDirective } from './mxv-gautocomplete/mxv-gautocomplete.directive';
import { MxvSwipeDirective } from './mxv-swipe/mxv-swipe.directive';

@NgModule({
  declarations: [MxvGautocompleteDirective, MxvSwipeDirective],
  imports: [CommonModule],
  exports: [MxvGautocompleteDirective, MxvSwipeDirective],
})
export class MxvDirectivesModule {}
