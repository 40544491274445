import { ComponentType, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import { Injectable, Injector, ViewChild } from '@angular/core';

import { DIALOG_DATA } from './tolis-dialog-tokens';
import { TolisDialogRef } from './tolis-dialogref';

export interface TolisDialogConfig {
  data?: any;
}

@Injectable({
  providedIn: 'root',
})
export class TolisDialogService {
  overlayRef: OverlayRef;
  portal: any;
  constructor(private overlay: Overlay, private injector: Injector) {}

  open<T>(
    component: ComponentType<T>,
    config?: TolisDialogConfig
  ): TolisDialogRef {
    const positionStrategy = this.overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically();

    // Create the overlay
    this.overlayRef = this.overlay.create({
      hasBackdrop: true,
      backdropClass: 'tolis-backdrop-on-mobile',
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy: positionStrategy,
      panelClass: 'overlay-panel',
    });

    // Create dialogRef to return
    const _tolisDialogRef = new TolisDialogRef(this.overlayRef);

    // Create injector to be able to reference the DialogRef from within the component
    // Detach the overlay from the portal on backdrop click
    this.overlayRef.backdropClick().subscribe((g) => {
      _tolisDialogRef.close();
    });

    const injector = Injector.create({
      parent: this.injector,
      providers: [
        { provide: TolisDialogRef, useValue: _tolisDialogRef },
        { provide: DIALOG_DATA, useValue: config?.data },
      ],
    });

    // Attach component portal to the overlay
    this.portal = new ComponentPortal(component, null, injector);
    this.overlayRef.attach(this.portal);

    return _tolisDialogRef;
  }

  close(): void {
    // If overlay exists and attached...
    if (this.overlayRef && this.overlayRef.hasAttached()) {
      // Detach it
      this.overlayRef.detach();
    }

    // If template portal exists and attached...
    if (this.portal && this.portal.isAttached) {
      // Detach it
      this.portal.detach();
    }
  }
}
