<!-- Footer-->
<footer class="position-relative z-index-10 d-print-none">
  <!-- Main block - menus, subscribe form-->
  <div class="py-6 bg-gray-200 text-muted">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 mb-5 mb-lg-0">
          <div class="fw-bold text-uppercase text-dark mb-3">
            Legal Notice
          </div>
          <div>
            <address>
              <strong>Legal Notice</strong><br>
              <div><strong>Legal Name:</strong> STAY &amp; ENJOY WITH US LTD</div>
              <div><strong>Registration No.:</strong> HE388120</div>
              <div><strong>VAT No.:</strong> CY10388120O</div>
              <div><strong>Legal/Registed Address:</strong> 16 Kappari Str, Paralimni, Famagusta, Cyprus 5290</div>
              <abbr title="Phone"><i class="fa fa-phone"></i> </abbr> +357 23 831 222<br>
            </address>

          </div>
          <p>
            <a href="https://goo.gl/maps/3KVQVZpGhXq3W3Kr9" target="_blank" rel="noreferrer">Office Location 35.024102,
              34.037730</a>
          </p>
          <div class="social">
            Social Media
            <ul class="list-inline">
              <li class="list-inline-item">
                <a href="https://x.com/myexclusivev" target="_blank" name="Myexclusive Villas Twitter">
                  <i class="fab fa-twitter"></i>
                </a>
              </li>
              <li class="list-inline-item">
                <a href="https://www.facebook.com/MyExclusiveVillasCommunity" target="_blank">
                  <i class="fab fa-facebook"></i>
                </a>
              </li>
              <li class="list-inline-item">
                <a href="https://www.pinterest.com/exclusivevillas/" target="_blank">
                  <i class="fab fa-pinterest"></i>
                </a>
              </li>
              <li class="list-inline-item">
                <a href="https://www.linkedin.com/company/myexclusivevilas-com" target="_blank">
                  <i class="fab fa-linkedin"></i>
                </a>
              </li>
            </ul>
          </div>

        </div>
        <div class="col-lg-2 col-md-6 mb-5 mb-lg-0">
          <h6 class="text-uppercase text-dark mb-3">Info</h6>
          <ul class="list-unstyled">
            <li>
              <a [routerLink]="['/page/privacy-policy']" class="text-muted">Privacy Policy
              </a>
            </li>
            <li>
              <a [routerLink]="['/page/cookies-policy']" class="text-muted">Cookies Policy
              </a>
            </li>
            <li>
              <a [routerLink]="['/page/terms-and-conditions']" class="text-muted">Terms And Conditions
              </a>
            </li>
            <li>
              <a [routerLink]="['/page/f-a-q']" class="text-muted">F.A.Q. </a>
            </li>

          </ul>
        </div>
        <div class="col-lg-2 col-md-6 mb-5 mb-lg-0">
          <h6 class="text-uppercase text-dark mb-3"></h6>
          <!-- <ul class="list-unstyled">
            <li>
              <a href="compare.html" class="text-muted">Comparison
                <span class="badge bg-info-light ms-1">New</span>
              </a>
            </li>
            <li>
              <a href="team.html" class="text-muted">Team <span class="badge bg-info-light ms-1">New</span>
              </a>
            </li>
            <li><a href="contact.html" class="text-muted">Contact </a></li>
            <li><a href="pricing.html" class="text-muted">Pricing </a></li>
            <li><a href="text.html" class="text-muted">Text page </a></li>
            <li><a href="faq.html" class="text-muted">F.A.Q.s </a></li>
            <li>
              <a href="coming-soon.html" class="text-muted">Coming soon </a>
            </li>
          </ul> -->
        </div>
        <div class="col-lg-4">
          <h6 class="text-uppercase text-dark mb-3">
            Exclusive deals and last minute offers
          </h6>
          <p class="mb-3">
            Join now if you want us to let you know about special offers, last
            minute deals, and new additions
          </p>
          <form action="#" id="newsletter-form">
            <div class="input-group mb-3">
              <input type="email" placeholder="Your Email Address" aria-label="Your Email Address"
                class="form-control bg-transparent border-dark border-right-0" />
              <div class="input-group-append">
                <button type="submit" class="btn btn-outline-dark border-start-0">
                  <i class="fa fa-paper-plane text-lg"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- Copyright section of the footer-->
  <div class="py-4 fw-light bg-gray-800 text-gray-300">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-6 text-center text-md-left">
          <p class="text-sm mb-md-0">
            &copy; 2020 Myexclusive Villas Limited. All rights reserved.
          </p>
        </div>
        <div class="col-md-6">
          <ul class="list-inline mb-0 mt-2 mt-md-0 text-center text-md-right">
            <li class="list-inline-item">
              <img src="assets/img/visa.svg" alt="..." class="w-2rem" />
            </li>
            <li class="list-inline-item">
              <img src="assets/img/mastercard.svg" alt="..." class="w-2rem" />
            </li>
            <li class="list-inline-item">
              <img src="assets/img/paypal.svg" alt="..." class="w-2rem" />
            </li>

          </ul>
        </div>
      </div>
    </div>
  </div>
</footer>

<!-- /Footer end-->