import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'empty-layout',
    templateUrl: './empty.component.html',
    styleUrls: ['./empty.component.css'],
    standalone: false
})
export class EmptyComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
