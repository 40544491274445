<div class="outer-inline-calendar bg-white">
  <div *ngIf="selectionLabel != '' && calendarOptions.showTitle" [ngClass]="{
      'text-secondary fw-bold':
        selectionLabel.toLowerCase() === 'dates are not available' || selectionLabel.toLowerCase() === 'the minimum stay is longer than the dates selected'
    }" class="d-flex justify-content-between border-bottom px-2 py-2 inline-calendar-header">
    <div [@fadeInOut]="selectionLabel">{{ selectionLabel }} ...</div>
    <div>
      <button (click)="close()" *ngIf="calendarLayout === 'popup'" type="button" class="btn-close"
        aria-label="Close"></button>
    </div>
  </div>
  <div class=" " (touchstart)="swipe($event, 'start')" (touchend)="swipe($event, 'end')">
    <div class="d-flex justify-content-start">
      <div class="w-100 p-2">
        <div class="d-flex justify-content-between">
          <div>
            <a href="javascript:void(0);" (click)="prev()">
              <i class="fas fa-chevron-left"></i>
            </a>
          </div>
          <div>
            <a>
              {{ days[0] | date : "MMM yyyy" }}
            </a>
          </div>

          <ng-container *ngIf="smallDevice; else elseTemplate">
            <div>
              <a href="javascript:void(0)" (click)="next()">
                <i class="fas fa-chevron-right"></i>
              </a>
            </div>
          </ng-container>
          <ng-template #elseTemplate>
            <div>&nbsp;</div>
          </ng-template>
        </div>

        <div class="inline-calendar" [ngClass]="{ popup: calendarLayout === 'popup' }">
          <div class="dn">Su</div>
          <div class="dn">Mo</div>
          <div class="dn">Tu</div>
          <div class="dn">We</div>
          <div class="dn">Th</div>
          <div class="dn">Fr</div>
          <div class="dn">Sa</div>

          <div class="cell" *ngFor="let day of days; let i = index" [ngClass]="{
              disabled: isOccupied(day, true) ,
              selected:
                (selectedStartDate &&
                  selectedStartDate.format('Y-MM-DD') ==
                    day.format('Y-MM-DD')) ||
                (selectedEndDate &&
                  selectedEndDate.format('Y-MM-DD') == day.format('Y-MM-DD')),
              selectedstart: selectedStartDate == day,
              selectedend: selectedEndDate == day || dayIsHovered == day,
              pattixa:
                (selectedStartDate &&
                  selectedEndDate &&
                  day.valueOf() >= selectedStartDate.valueOf() &&
                  day.valueOf() <= selectedEndDate.valueOf()) ||
                (selectedStartDate &&
                  fakeEndDate &&
                  !selectedEndDate &&
                  day.valueOf() >= selectedStartDate.valueOf() &&
                  day.valueOf() <= fakeEndDate.valueOf())
            }" (mouseenter)="dayIsHovered = day; setFakeEndDate(day)" [attr.data-no]="day.valueOf()"
            (click)="onCalendarClicked(day, true)" [style.grid-column]="column(i, days)">
            <div class="ic-day" [ngClass]="{ disabled: isOccupied(day, true) }">
              {{ day | date : "d" }}
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex h-100">
        <div class="vr"></div>
      </div>
      <div class="w-100 p-2" *ngIf="!smallDevice">
        <div class="d-flex justify-content-between">
          <div></div>
          <div>
            <a>
              {{ nextMonthDays[0] | date : "MMM yyyy" }}
            </a>
          </div>
          <div>
            <a href="javascript:void(0)" (click)="next()">
              <i class="fas fa-chevron-right"></i>
            </a>
          </div>
        </div>
        <div class="inline-calendar" [ngClass]="{ popup: calendarLayout === 'popup' }">
          <div class="dn">Su</div>
          <div class="dn">Mo</div>
          <div class="dn">Tu</div>
          <div class="dn">We</div>
          <div class="dn">Th</div>
          <div class="dn">Fr</div>
          <div class="dn">Sa</div>

          <div class="cell" *ngFor="let nmday of nextMonthDays; let i = index" [ngClass]="{
              selected:
                (selectedStartDate &&
                  selectedStartDate.format('Y-MM-DD') ==
                    nmday.format('Y-MM-DD')) ||
                (selectedEndDate &&
                  selectedEndDate.format('Y-MM-DD') == nmday.format('Y-MM-DD')),

              selectedstart: selectedStartDate == nmday,
              selectedend: selectedEndDate == nmday || dayIsHovered == nmday,
              pattixa:
                (selectedStartDate &&
                  selectedEndDate &&
                  nmday.valueOf() >= selectedStartDate.valueOf() &&
                  nmday.valueOf() <= selectedEndDate.valueOf()) ||
                (selectedStartDate &&
                  fakeEndDate &&
                  !selectedEndDate &&
                  nmday.valueOf() >= selectedStartDate.valueOf() &&
                  nmday.valueOf() <= fakeEndDate.valueOf())
            }" (mouseenter)="dayIsHovered = nmday; setFakeEndDate(nmday)" [attr.data-no]="nmday.valueOf()"
            [style.grid-column]="column(i, nextMonthDays)" (click)="onCalendarClicked(nmday, true)">
            <span class="ic-day" [ngClass]="{ disabled: isOccupied(nmday, true) }">{{ nmday | date : "d" }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>