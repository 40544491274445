import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { PopupPanelRef } from '../popup-panel/popup-panelref';
import { PANEL_DATA } from '../popup-panel/popup-panel-tokens';
import { Subject } from 'rxjs';
import { Guests, GuestType } from '../guest-picker/guest-picker.types';

@Component({
  selector: 'app-mxv-guest-picker',
  templateUrl: './mxv-guest-picker.component.html',
  styleUrl: './mxv-guest-picker.component.css',
  standalone: false,
})
export class MxvGuestPickerComponent implements OnDestroy, OnInit {
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  public guests: GuestType = new Guests();

  /**
   * constructor
   * @param _popupPanelRef
   * @param data
   */
  constructor(
    private _popupPanelRef: PopupPanelRef,
    @Inject(PANEL_DATA) public data: GuestType
  ) {}

  ngOnInit(): void {
    this.guests = this.data;
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  onGuestPickerVisibilityChanged(event): void {
    if (!event.visible) {
      this.dismiss(this.guests);
    }
  }

  onGuestNumberChanged(_guests: GuestType): void {
    this.guests = _guests;
  }

  dismiss(how: string | GuestType): void {
    this._popupPanelRef.close(how);
  }
}
