import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { feature } from '../models/feature';

@Injectable({
  providedIn: 'root',
})
export class FavoriteService {
  private _favorites: BehaviorSubject<feature[]> = new BehaviorSubject(null);

  favoritesChanged = new Subject<boolean>();
  observer = this.favoritesChanged.asObservable();

  constructor(private storage: StorageMap, private _httpClient: HttpClient) {}

  // ------------------------------------------------------------------------------- //
  // @ Accessors
  // ------------------------------------------------------------------------------- //
  get favorites$(): Observable<any[]> {
    return this._favorites.asObservable();
  }

  public addToFavorites(propertyId: number) {
    this.storage.get('favorites').subscribe((result: []) => {
      const newFavorites = result ? result : [];

      if (!newFavorites.includes(propertyId)) {
        newFavorites.push(propertyId);
        this.storage.set('favorites', newFavorites).subscribe({
          next: () => {
            this.favoritesChanged.next(true);
          },
        });
      }
    });
  }

  public removeFromFavorites(propertyId: number) {
    this.storage.get('favorites').subscribe((result: []) => {
      const newFavorites = result ? result : [];

      const itemLocation = newFavorites.indexOf(propertyId);
      // if it exists remove it
      if (itemLocation !== -1) {
        newFavorites.splice(itemLocation, 1);

        this.storage.set('favorites', newFavorites).subscribe({
          next: () => {
            this.favoritesChanged.next(true);
          },
        });
      }
    });
  }

  public getFavorites(): Observable<any> {
    return this.storage.get('favorites').pipe(
      take(1),
      switchMap((fav: any) => {
        const favoritesIds = fav?.length > 0 ? fav?.join(',') : 0;
        return this._httpClient
          .get<any[]>(
            `${environment.endpoints.api}/v1/user-favorites/${favoritesIds}`
          )
          .pipe(
            map((_favorites: feature[]) => {
              this._favorites.next(_favorites);
              return _favorites;
            })
          );
      })
    );
  }

  // public getFavoriteRentals(ids: []): Observable<any[]> {
  //   return;
  // }
  private handleError(error: HttpErrorResponse) {
    // return an observable with a user-facing error message

    return throwError(
      () =>
        'An error occured while retrieving the favorites. Try refreshing the page'
    );
  }
}
