import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageComponent } from './page/page.component';
import { AmenitiesComponent } from './amenities/amenities.component';
import { DialogsService } from './dialogs.service';
import { SocialShareComponent } from './social-share/social-share.component';
import { MoreFiltersComponent } from './more-filters/more-filters.component';
import { MainPipe } from '../pipes/main-pipe.module';
import { StaticDataDialogComponent } from './static-data-dialog/static-data-dialog.component';
import { SignupDialog } from './signup/signup.dialog';
import { ComponentsModule } from '../shared/components/components.module';
import { MagicLinkSigninDialog } from './magic-link-signin/magic-link-signin.dialog.component';
import { AccountInfoComponent } from './signup/steps/account-info/account-info.component';
import { SuccessfulSignupComponent } from './signup/steps/successful-signup/successful-signup.component';
import { VerifyEmailComponent } from './signup/steps/verify-email/verify-email.component';

@NgModule({
  declarations: [
    LoginComponent,
    PageComponent,
    AmenitiesComponent,
    SocialShareComponent,
    MoreFiltersComponent,
    StaticDataDialogComponent,
    SignupDialog,
    MagicLinkSigninDialog,
    AccountInfoComponent,
    // VerifyMobileComponent,
    VerifyEmailComponent,
    SuccessfulSignupComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MainPipe,
    ComponentsModule,
  ],
  providers: [DialogsService],
})
export class DialogsModule {}
