import { Component } from '@angular/core';

@Component({
  selector: 'app-link-expired',
  imports: [],
  templateUrl: './link-expired.component.html',
  styleUrl: './link-expired.component.css'
})
export class LinkExpiredComponent {

}
