import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { Guests, GuestType } from './guest-picker.types';
import { mxvAnimations } from 'src/@tolis/animations';

@Component({
  selector: 'app-guest-picker',
  templateUrl: './guest-picker.component.html',
  styleUrl: './guest-picker.component.css',
  encapsulation: ViewEncapsulation.None,
  animations: mxvAnimations,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
/** */
export class GuestPickerComponent implements OnDestroy {
  @Input() guests: GuestType;

  @Output() guestsChanged = new EventEmitter<GuestType>();
  @Output() guestPickerIsVisible = new EventEmitter<{ visible: boolean }>();

  constructor(private _changeDetector: ChangeDetectorRef) {}

  incrementGuests(guestType: string, numberOfGuest: number) {
    this.guests[guestType] += numberOfGuest;
    if (this.guests[guestType] < 0) {
      this.guests[guestType] = 0;
    }

    this.guestsChanged.emit(this.guests);
    this._changeDetector.markForCheck();
  }

  close() {
    this.guestPickerIsVisible.emit({ visible: false });
    this._changeDetector.markForCheck();
  }
  open() {
    this.guestPickerIsVisible.emit({ visible: true });
    this._changeDetector.markForCheck();
  }

  ngOnDestroy(): void {
    this.guestPickerIsVisible.emit({ visible: false });
    this._changeDetector.markForCheck();
  }
}
