<!-- guests -->
<div class="card border-0 guest-picker">
    <div class="card-body">
        <h5 class="card-title">* Select Your Guests</h5>
        <div class="card-text">

            <div class="outer-inline-calendar ">
                <div class="row">
                    <div class="col-12">
                        <div class="row minplus-row">
                            <div class="col-6">
                                <span class="badge bg-secondary text-lg">{{
                                    guests.adults
                                    }} </span> Adults
                            </div>
                            <div class="col-6">
                                <button class="btn btn-outline-primary rounded-circle" style="margin-right: 8px"
                                    (click)="incrementGuests('adults', -1)">
                                    <i class="fa-solid fa-minus"></i>
                                </button>
                                <button class="btn btn-outline-primary rounded-circle"
                                    (click)="incrementGuests('adults', 1)">
                                    <i class="fa-solid fa-plus"></i>
                                </button>
                            </div>
                        </div>
                        <div class="tol-divider"></div>
                        <div class="row minplus-row">
                            <div class="col-6">
                                <span class="badge bg-secondary text-lg">{{
                                    guests.children
                                    }} </span> Children

                            </div>
                            <div class="col-6">
                                <button class="btn btn-outline-primary rounded-circle" style="margin-right: 8px"
                                    (click)="incrementGuests('children', -1)">
                                    <i class="fa-solid fa-minus"></i>
                                </button>
                                <button class="btn btn-outline-primary rounded-circle" style="margin-right: 8px"
                                    (click)="incrementGuests('children', 1)">
                                    <i class="fa-solid fa-plus"></i>
                                </button>
                            </div>
                        </div>
                        <div class="tol-divider"></div>
                        <div class="row minplus-row">
                            <div class="col-6">
                                <span class="badge bg-secondary text-lg">{{
                                    guests.infants
                                    }}</span>
                                Infants
                            </div>
                            <div class="col-6">
                                <button class="btn btn-outline-primary rounded-circle" style="margin-right: 8px"
                                    (click)="incrementGuests('infants', -1)">
                                    <i class="fa-solid fa-minus"></i>
                                </button>
                                <button class="btn btn-outline-primary rounded-circle"
                                    (click)="incrementGuests('infants', 1)">
                                    <i class="fa-solid fa-plus"></i>
                                </button>
                            </div>
                        </div>
                        <div class="tol-divider"></div>

                    </div>
                </div>
            </div>

        </div>
        <div class="d-grid gap-2">
            <button class="btn btn-primary mt-4" type="button" (click)="close()">Update</button>
        </div>

    </div>
</div>



<!-- EO GUESTS-->