import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'default-layout',
    templateUrl: './default.component.html',
    styleUrls: ['./default.component.css'],
    standalone: false
})
export class DefaultComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
