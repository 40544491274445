import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrl: './verify-email.component.css',
  standalone: false,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VerifyEmailComponent implements OnInit {
  @Input() verifyToken: string = '';
  @Output() stepValidity = new EventEmitter<any>();

  verifyForm: UntypedFormGroup;

  _unsubscribeAll: Subject<any> = new Subject<any>();

  /**
   * constructor
   */

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.verifyForm = this.formBuilder.group({
      code: ['', [Validators.required, Validators.minLength(6)]],
      v: [this.verifyToken],
    });

    this.verifyForm.statusChanges
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result) => {
        if (result === 'VALID') {
          this.stepValidity.emit({
            formValues: this.verifyForm.value,
            submitResult: '',
            isValid: true,
          });
        }
      });
  }

  submitVerificationCode(): void {}
}
