import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { IsoCountry } from 'src/app/models/iso-country';
import { SignupService } from '../../signup.service';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-account-info',
  templateUrl: './account-info.component.html',
  styleUrl: './account-info.component.css',
  standalone: false,
})
export class AccountInfoComponent implements OnInit {
  @ViewChild('countryField') private _countryField: ElementRef;

  @Output() stepValidity = new EventEmitter<any>();

  _unsubscribeAll: Subject<void> = new Subject();

  showRegisterForm: boolean = true;
  showVerifyCodeForm: boolean = false;
  showFinished: boolean = false;

  signUpForm: UntypedFormGroup;
  isoCountries: IsoCountry[];
  formError: any = {};
  // to show spinning
  working = false;
  showAlert = false;
  alert = {
    type: 'danger',
    message: '',
  };
  verifyCodeAlert = {
    type: '',
    message: '',
  };

  get c() {
    return this.signUpForm.controls;
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private _signupService: SignupService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _authService: AuthenticationService
  ) {}

  ngOnInit(): void {
    // Get the country telephone codes
    this._signupService.getCountries().subscribe();
    this._signupService.countries$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((codes: IsoCountry[]) => {
        this.isoCountries = codes;
        // Mark for check...
        // this._changeDetectorRef.markForCheck();
      });

    this.signUpForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      repeatpassword: ['', [Validators.required, Validators.minLength(8)]],
      firstname: ['', [Validators.required]],
      lastname: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      country: ['', [Validators.required]],
    });

    this.signUpForm.statusChanges
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result) => {
        if (result === 'VALID') {
          this.stepValidity.emit({
            formValues: this.signUpForm.value,
            submitResult: '',
            isValid: true,
          });
        }
      });
  }

  countryOnChange(): void {
    this.signUpForm.get('phone').setValue(this.signUpForm.get('country').value);
    this._countryField.nativeElement.focus();
  }

  onSubmit(formValues, event): boolean {
    // Do nothing if the form is invalid
    if (this.invalidFieldsExist()) {
      return false;
    }

    // Disable the form
    //this.signUpForm.disable();

    // Hide the alert
    this.showAlert = true;
    // Sign up

    this._authService.signUp(this.signUpForm.value).subscribe({
      next: (response) => {
        // Re-enable the form
        this.signUpForm.enable();
        // this.signUpForm.reset();

        this.alert = {
          type: 'success',
          message: '',
        };
        this.stepValidity.emit({
          formValues: this.signUpForm.value,
          submitResult: response.v,
          isValid: true,
        });
      },
      error: (err) => {
        let message = '';
        Object.keys(err.error).forEach((_message, index) => {
          message += `<p>${err.error[_message]}</p>`;
        });

        this.alert = {
          type: 'danger',
          message: message,
        };
        this.showAlert = true;
        this._changeDetectorRef.markForCheck();
        this.stepValidity.emit({
          formValues: this.signUpForm.value,
          submitResult: '',
          isValid: false,
        });
      },
    });
  }

  invalidFieldsExist(): boolean {
    return this.signUpForm.invalid;
  }
}

export interface SignupFormFields {
  email: string;
  password: string;
  repeatpassword: string;
  firstname: string;
  lastname: string;
  phone: string;
  country: string;
}
