export const environment = {
  production: true,
  application: {
    name: 'mxv-frontend',
    angular: 'Angular 19',
    bootstrap: 'Bootstrap 5.3.3',
  },
  // apiUrl: 'http://localhost:8000',
  apiUrl: 'https://mxvapi.myexclusivevillas.com',
  endpoints: {
    api: 'https://mxvapi.myexclusivevillas.com',
    // api: 'http://localhost:8000/api',
    login: 'https://mxvapi.myexclusivevillas.com/auth/login',
    loginWithLink: 'https://mxvapi.myexclusivevillas.com/auth/link-login',
    logout: 'https://mxvapi.myexclusivevillas.com/auth/logout',
    signup: 'https://mxvapi.myexclusivevillas.com/auth/signup',
    storage: 'https://myexclusivevillas.com/storage',
  },
  siteUrl: 'https://myexclusivevillas.com',
  iss: {
    login: 'http://mxvapi.myexclusivevillas.com/auth/login',
    loginssl: 'https://mxvapi.myexclusivevillas.com/auth/login',
    linklogin: 'http://mxvapi.myexclusivevillas.com/auth/link-login',
    linkloginssl: 'https://mxvapi.myexclusivevillas.com/auth/link-login',
    verify: 'http://mxvapi.myexclusivevillas.com/api/auth/signup/verify-code',
    verify3: 'https://mxvapi.myexclusivevillas.com/api/auth/signup/verify-code',
    verify2: 'http://mxvapi.myexclusivevillas.com/auth/signup/verify-code',
    signup: 'https://mxvapi.myexclusivevillas.com/auth/signup',
    google: 'https://mxvapi.myexclusivevillas.com/auth/login/google/callback',
    facebook:
      'https://mxvapi.myexclusivevillas.com/auth/login/facebook/callback',
  },
  cdnUrl: 'https://myexclusivevillas.com/storage',
};
