import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { PopupPanelRef } from '../popup-panel/popup-panelref';
import { PANEL_DATA } from '../popup-panel/popup-panel-tokens';
import { MxvRangePickerService } from './mxv-rangepicker.service';
import { Minstay } from '../inline-calendar/inline-calendar.component';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-mxv-rangepicker',
  templateUrl: './mxv-rangepicker.component.html',
  styleUrls: ['./mxv-rangepicker.component.css'],
  standalone: false,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MxvRangepickerComponent implements OnInit, OnDestroy {
  public dateRange: string;
  public startDate: Date;
  public endDate: Date;
  public smallDevice = false;
  bookedDays = [];
  dayPriceInfo = [];
  minStays: Minstay[] = [];

  private _unsubscribeAll: Subject<any> = new Subject<any>();
  constructor(
    private _popupPanelRef: PopupPanelRef,
    @Inject(PANEL_DATA) public data: any,
    private _service: MxvRangePickerService
  ) {}

  ngOnInit(): void {
    this.bookedDays = this.data.bookedDates ?? [];
    this.minStays = this.data.minStays;
    this.dayPriceInfo = this.data.dayPriceInfo ?? [];
    this.startDate = this.data?.startDate ?? undefined;
    this.endDate = this.data?.endDate ?? undefined;
    this.smallDevice = this.data.smallDevice;
  }
  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
  onDatesSelectedT(dt: { from: string; to: string }) {
    // this.searchInformation.dates = dt.from + '_' + dt.to;
    this.dateRange = `${dt.from}_${dt.to}`;
    // tslint:disable-next-line:quotemark
    this.startDate = new Date(dt.from);

    // tslint:disable-next-line:quotemark
    this.endDate = new Date(dt.to);

    this._popupPanelRef.close(dt);
  }

  dismiss(how: string): void {
    this._popupPanelRef.close(how);
  }

  clearDates(): void {
    this._popupPanelRef.close('');
  }

  onCalendarVisibilityChanged(event): void {
    if (!event.visible) {
      this.dismiss('fromX');
    }
  }
}
