<form class="form-validate" [formGroup]="verifyForm">

    <div class="alert alert-info">
        You need to verify your Email Address. An Email with a 6 digit code was
        sent to your Email Address
    </div>
    <div class="form-group mb-2 pb-4">
        <input type="text" class="form-control" id="code" [formControlName]="'code'" placeholder="Verification Code"
            autocomplete="false" autofocus="true" />
        <div class="invalid-feedback">Valid 6 Digit Code is required.</div>
        <!-- Submit  -->
    </div>
</form>