<form *ngIf="showRegisterForm" class="form-validate" [formGroup]="signUpForm"
    (ngSubmit)="onSubmit(signUpForm.value, $event)">

    <!-- Login -->
    <div class="px-md-3 px-xl-2">

        <div class="form-floating mb-2">
            <input type="text" class="form-control" id="email" [formControl]="signUpForm.get('email')"
                placeholder="Email Address" autocomplete="false" value="" required=""
                [ngClass]="{'is-invalid': signUpForm.get('email').invalid && signUpForm.get('email').dirty}"
                autofocus="true" />
            <label for="email">Email</label>
            <div *ngIf="signUpForm.get('email').invalid && signUpForm.get('email').dirty" class="invalid-feedback">
                Valid Email is required.
            </div>
        </div>

        <div class="form-floating mb-2">
            <input name="loginPassword" id="loginPassword" placeholder="Password" type="password" required
                data-msg="Please enter your password" class="form-control"
                [ngClass]="{'is-invalid': signUpForm.get('password').invalid && signUpForm.get('password').dirty}"
                [formControl]="signUpForm.get('password')" />
            <label for="loginPassword"> Password</label>
            <div *ngIf="signUpForm.get('password').invalid && signUpForm.get('password').dirty"
                class="invalid-feedback">
                Password must be at least 8 characters long
            </div>
        </div>

        <div class="form-floating mb-2">
            <input name="repeatpassword" id="repeatpassword" placeholder="Repeat-Password" type="password" required
                data-msg="Please repeat your password" class="form-control"
                [ngClass]="{'is-invalid': signUpForm.get('repeatpassword').invalid && signUpForm.get('repeatpassword').dirty}"
                [formControl]="signUpForm.get('repeatpassword')" />
            <label for="repeatpassword">Repeat Password</label>

            <div class="invalid-feedback"
                *ngIf="signUpForm.get('repeatpassword').value !== signUpForm.get('password').value">
                Password and Repeat password do not match
            </div>
        </div>
        <hr data-content="Contact Info" class="my-4 hr-text letter-spacing-2" />
        <div class="row">
            <div class="col-md-6 mb-2">
                <div class="form-floating">
                    <input type="text" class="form-control" id="firstName" placeholder="Firstname"
                        [formControl]="signUpForm.get('firstname')" value="" required="" />
                    <label for="firstName">First name</label>

                    <div class="invalid-feedback">
                        Valid first name is required.
                    </div>
                </div>
            </div>
            <div class="col-md-6 mb-3">
                <div class="form-floating">
                    <input type="text" class="form-control" id="lastName" [formControl]="signUpForm.get('lastname')"
                        placeholder="Lastname" value="" required="" />
                    <label for="lastName">Last name</label>
                    <div class="invalid-feedback">
                        Valid last name is required.
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 mb-3">
                <div class="form-floating">
                    <select name="country" id="country" class="form-control" placeholder="Country"
                        [formControl]="signUpForm.get('country')" #countryField (change)="countryOnChange()">
                        <option value="" selected></option>
                        <ng-container *ngFor="let country of isoCountries">
                            <option [value]="country.code">{{ country.name }}</option>
                        </ng-container>
                    </select>

                    <label for="country">Country</label>
                </div>
            </div>
            <div class="col-md-6 mb-3">
                <div class="form-floating">
                    <input type="text" class="form-control" id="phone" [formControl]="signUpForm.get('phone')"
                        placeholder="Phone Number" value="" required="true" />
                    <label for="phone">Phone Number</label>
                </div>
            </div>
        </div>
    </div>

</form>