export class User {
  id: number;
  email: string;
  name: string;
  firstname: string;
  lastname: string;
  avatar: string;
  avatarUrl: string;
  expires: number;
  phone: string;
  country: string;
  address: string;
  city: string;
  zip: string;
  gender: string;

  constructor(user?) {
    this.id = user.id || -1;
    this.name = user.name || '';
    this.email = user.email || '';
    this.avatar = user.avatar || '';
    this.avatarUrl = user.avatarUrl || '';
    this.expires = user.expires || 0;
    this.phone = user.phone || '';
    this.country = user.country || '';
    this.address = user.address || '';
    this.city = user.city || '';
    this.zip = user.zip || '';
    this.firstname = user.firstname || '';
    this.lastname = user.lastname || '';
    this.gender = user.gender || '';
  }
}
