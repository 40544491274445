export interface GuestType {
  adults: number;
  children: number;
  infants: number;
}

export class Guests implements GuestType {
  public adults: number = 0;
  public children: number = 0;
  public infants: number = 0;
}
