import { CommonModule, DatePipe } from '@angular/common';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { LayoutModule } from 'src/app/layout/layout.module';
import { HomeComponent } from './home.component';
// RECOMMENDED
import { HomeService } from './home.service';
import { ComponentsModule } from 'src/app/shared/components/components.module';
import { MxvDirectivesModule } from 'src/app/shared/directives/mxv-directives.module';

@NgModule({
  declarations: [HomeComponent],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule,
    RouterModule,
    LayoutModule,
    MxvDirectivesModule,
  ],
  providers: [
    HomeService,
    DatePipe,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class HomeModule {}
