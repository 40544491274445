<header class="header">

  <nav class="navbar navbar-expand-lg shadow navbar-light bg-white" aria-label="Offcanvas navbar large">
    <div class="container-fluid">
      <div class="d-flex align-items-center">
        <a title="Myexclusive Villas - Homepage" class="navbar-brand py-1 logos-top" [routerLink]="['/']">
          <img src="/assets/img/mxv2.png" alt="Myexclusive Villas - Homepage" title="Myexclusive Villas - Homepage"
            class="mxv-logo"><img src="/assets/img/icons/7.png" [src]="'/assets/img/icons/'+logoImage"
            title="Myexclusive Villas - Homepage" alt="Myexclusive Villas - Homepage" class="mxv-logo"
            style="margin-left: 4px;"></a>

        <form [formGroup]="searchForm" (ngSubmit)="onSubmit()" class="form-inline d-none d-sm-flex" action="#"
          id="search" data-bs-dismiss="#offcanvas">
          <div class="input-label-absolute input-label-absolute-left input-expand ms-lg-2 ms-xl-3 ">
            <label class="label-absolute" for="search_search"><i class="fa fa-search"></i><span class="sr-only">Location
                or Property</span></label>

            <input type="text" appMxvGautocomplete [formControlName]="'searchbox'"
              (placeSelected)="onPlacesBoxChange($event)"
              class="form-control form-control-sm border-0 shadow-0 bg-gray-200">

          </div>
        </form>
      </div>

      <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar2"
        aria-controls="offcanvasNavbar2">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="offcanvas offcanvas-end " tabindex="-1" id="offcanvasNavbar2" aria-labelledby="offcanvasNavbar2Label">
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="offcanvasNavbar2Label">Myexclusive Villas</h5>
          <button type="button" class="btn-close btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
          <form class="form-inline mt-4 mb-2 d-sm-none" action="#" id="searchcollapsed" [formGroup]="searchForm"
            (ngSubmit)="onSubmit()">
            <div class="input-label-absolute input-label-absolute-left w-100">
              <label class="label-absolute" for="searchcollapsed_search"><i class="fa fa-search"></i><span
                  class="sr-only">Location or Property</span></label>
              <input type="text" appMxvGautocomplete [formControlName]="'searchbox'"
                (placeSelected)="onPlacesBoxChange($event)"
                class="form-control form-control-sm border-0 shadow-0 bg-gray-200">

            </div>
          </form>

          <ul class="navbar-nav ms-auto ">
            <li class="nav-item">
              <a class="nav-link" [routerLink]="['/areas']" data-bs-dismiss="offcanvas" aria-label="Close">
                <i class="fa fa-map-pin"></i>
                Destinations</a>
            </li>
            <li class="nav-item divider-left">
              <a class="nav-link" [routerLink]="['/blog']" data-bs-dismiss="offcanvas">Blog</a>
            </li>

            <li class="nav-item dropdown divider-left">
              <a class="nav-link dropdown-toggle" id="helpDropdownMenuLink" href="javascript:void(0)"
                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Support</a>
              <div class="dropdown-menu dropdown-menu-end" aria-labelledby="helpDropdownMenuLinkLink">
                <a [routerLink]="['/page/f-a-q']" class="dropdown-item" data-bs-dismiss="offcanvas"
                  aria-label="Close">F.A.Q</a>
                <a [routerLink]="['/contactus']" class="dropdown-item" data-bs-dismiss="offcanvas"
                  aria-label="Close">Contact Us</a>
              </div>
            </li>
            <li class="nav-item divider-left">


              <a [routerLink]="['/user/favorites']" routerLinkActive="router-link-active" class="nav-link  "
                title="favorites">
                <span class="badge  badge-secondary-light mr-2">
                  <i class="fas fa-heart text-secondary "></i>
                  {{favorites.length}} </span>
                Favorites
              </a>
            </li>

            <li class="nav-item divider-left" *ngIf="loggedIn != true">
              <a href="javascript:void(0)" class="nav-link" (click)="openLogin()" data-bs-dismiss="offcanvas"><i
                  class="fa-solid fa-user"></i>
                Login</a>
            </li>

            <li class="nav-item divider-left" *ngIf="loggedIn != true">
              <a href="javascript:void(0)" class="nav-link" (click)="openSignupModal()" data-bs-dismiss="offcanvas"><i
                  class="fa-solid fa-user-plus"></i>
                Sign-Up</a>
            </li>

            <li class="nav-item dropdown divider-left" *ngIf="loggedIn">
              <a class="nav-link dropdown-toggle " href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
                aria-expanded="false">

                <i class="fa-solid fa-user"></i>
                {{ user.firstname }}
              </a>
              <ul class="dropdown-menu dropdown-menu-end divider-left" aria-labelledby="navbarDropdown">
                <li>
                  <a class="dropdown-item" [routerLink]="['/user/profile']">My Profile</a>
                </li>
                <li>
                  <a class="dropdown-item" [routerLink]="['/user/bookings']">My Bookings</a>
                </li>
                <!-- <li>
                <a class="dropdown-item" href="#">Refer A Property Owner</a>
              </li>
              <li>
                <a class="dropdown-item" href="#">Invite friends <small> get a 5% discount</small></a>
              </li> -->
                <li class="dropdown-divider"></li>
                <li>
                  <button (click)="logout($event)" class="dropdown-item" style="cursor: pointer">
                    <i class="fas fa-sign-out-alt mr-2 text-muted"></i> Log Out
                  </button>
                </li>

              </ul>

            </li>
            <li class="nav-item mt-3 mt-lg-0 ms-lg-3 d-lg-none d-xl-inline-block divider-left"><a
                class="btn btn-primary" rel="noopener" href="https://owners.myexclusivevillas.com">Owners Area</a></li>
          </ul>
        </div>
      </div>
    </div>
  </nav>


</header>