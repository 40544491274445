import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Minstay } from '../inline-calendar/inline-calendar.component';

@Injectable({
  providedIn: 'root',
})
export class MxvRangePickerService {
  private _minStays: BehaviorSubject<Minstay[] | null> = new BehaviorSubject(
    null
  );

  constructor(private _httpClient: HttpClient) {}

  /**
   * getter for _minStays
   */
  get minStays$(): Observable<Minstay[]> {
    return this._minStays.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  getMinStays(_propertyId: number): Observable<Minstay[]> {
    return this._httpClient
      .get<Minstay[]>(
        `${environment.endpoints.api}/v1/property/${_propertyId}/min-stays`
      )
      .pipe(
        tap((_minStays: Minstay[]) => {
          this._minStays.next(_minStays);
        })
      );
  }
}
