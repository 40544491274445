import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-star-rating',
    templateUrl: './star-rating.component.html',
    styleUrls: ['./star-rating.component.css'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class StarRatingComponent implements OnInit {
  @Input() rating: number;

  constructor() {
    this.rating = 0;
  }

  ngOnInit() {}
}
