import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, tap } from 'rxjs';
import { IsoCountry } from 'src/app/models/iso-country';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SignupService {
  // the list of all countries. get from db or from localstorage
  private countryList = new Subject<IsoCountry[] | null>();
  private _countries: BehaviorSubject<IsoCountry[] | null> =
    new BehaviorSubject(null);

  constructor(private _httpClient: HttpClient) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for countries
   */
  get countries$(): Observable<IsoCountry[]> {
    return this._countries.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get countries
   */
  getCountries(): Observable<IsoCountry[]> {
    const countryList = JSON.parse(localStorage.getItem('countryList'));

    if (countryList) {
      this._countries.next(countryList);

      return this._countries.asObservable();
    }

    return this._httpClient
      .get<IsoCountry[]>(environment.endpoints.api + '/v1/iso-country')
      .pipe(
        tap((countries) => {
          this._countries.next(countries);
          localStorage.setItem('countryList', JSON.stringify(countries));
        })
      );
  }
}
