import {
  CommonModule,
  DOCUMENT,
  isPlatformBrowser,
  ViewportScroller,
} from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptors,
} from '@angular/common/http';
import {
  APP_ID,
  importProvidersFrom,
  Inject,
  NgModule,
  PLATFORM_ID,
} from '@angular/core';

import {
  GuardsCheckEnd,
  NavigationEnd,
  Router,
  RouterModule,
  RouterOutlet,
  Routes,
  Scroll,
} from '@angular/router';
import { StorageModule } from '@ngx-pwa/local-storage';
import { filter } from 'rxjs/operators';
import { TolisModule } from 'src/@tolis';
import { MxvConfigModule } from 'src/@tolis/services/config/config.module';
import { AppComponent } from './app.component';
import { appConfig } from './app.config';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { LayoutComponent } from './layout/layout.component';
import { LayoutModule } from './layout/layout.module';
import { AllModule } from './modules/all.module';
import { HomeComponent } from './modules/home/home.component';
// import { RentalPhotosStandaloneComponent } from './modules/rental-photos-standalone/rental-photos-standalone.component';
import { WINDOW_PROVIDERS } from './services/window.service';
import { NgProgressbar } from 'ngx-progressbar';
import {
  NgProgressRouter,
  provideNgProgressRouter,
} from 'ngx-progressbar/router';
import { HttpErrorInterceptor } from './helpers/http-interceptor';
import {
  NgProgressHttp,
  progressInterceptor,
  provideNgProgressHttp,
} from 'ngx-progressbar/http';
import { ToastNoAnimationModule } from 'ngx-toastr';
import { provideClientHydration } from '@angular/platform-browser';
import { LinkExpiredComponent } from './modules/link-expired/link-expired.component';

const routes: Routes = [
  // {
  //   // path: 'gallery/:rentalId/:slug/photos/:startpos',
  //   path: 'accomodation/:rentalId/:slug/photos/:startpos',
  //   component: RentalPhotosStandaloneComponent,
  // },

  {
    path: 's',
    loadChildren: () =>
      import('./modules/shorturl/shorturl.module').then(
        (m) => m.ShorturlModule
      ),
  },

  // With Layout Children
  {
    path: '',
    component: LayoutComponent,
    children: [
      { path: '', component: HomeComponent },

      {
        path: 'accomodation',
        loadChildren: () =>
          import('./modules/rental/rental.module').then((m) => m.RentalModule),
      },
      {
        path: 'rental',
        loadChildren: () =>
          import('./modules/rental/rental.module').then((m) => m.RentalModule),
      },
      {
        path: 'contactus',
        loadChildren: () => import('./modules/contactus/contactus.routes'),
      },

      {
        path: 'blog',
        loadChildren: () =>
          import('./modules/blog/blog.module').then((m) => m.BlogModule),
      },
      {
        path: 'places',
        loadChildren: () =>
          import('./modules/places/places.module').then((m) => m.PlacesModule),
      },
      {
        path: 'areas',
        loadChildren: () =>
          import('./modules/areas/areas.module').then((m) => m.AreasModule),
      },
      {
        path: 'booking',
        loadChildren: () =>
          import('./modules/booking/booking.module').then(
            (m) => m.BookingModule
          ),
      },
      {
        path: 'page',
        loadChildren: () =>
          import('./modules/general-pages/general-pages.module').then(
            (m) => m.GeneralPagesModule
          ),
      },
      {
        path: 'link-login',
        loadChildren: () =>
          import('./modules/sign-in-with-link/sign-in-with-link.module').then(
            (m) => m.SignInWithLinkModule
          ),
      },
      {
        path: 'link-expired',
        component: LinkExpiredComponent,
      },
      {
        path: 'search',
        loadChildren: () =>
          import('./modules/search/search.module').then((m) => m.SearchModule),
      },

      {
        path: 's',
        loadChildren: () =>
          import('./modules/shorturl/shorturl.module').then(
            (m) => m.ShorturlModule
          ),
      },
      {
        path: 'user',
        loadChildren: () =>
          import('./modules/user/user.module').then((m) => m.UserModule),
      },
    ],
  },

  //No Layout Children
  {
    path: '',
    children: [
      {
        path: 'auth',
        loadChildren: () =>
          import('./modules/login/login.module').then((m) => m.LoginModule),
      },
    ],
  },
  { path: '**', redirectTo: '/', pathMatch: 'full' },
];
@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'disabled',
      anchorScrolling: 'enabled',
      initialNavigation: 'enabledNonBlocking',
    }),
    TolisModule,
    NgProgressbar,
    NgProgressRouter,
    NgProgressHttp,
    RouterOutlet,
    MxvConfigModule.forRoot(appConfig),

    LayoutModule,
    // FooterModule,
    //BrowserModule.withServerTransition({ appId: 'myExclusiveVillas' }),

    AllModule,

    // DialogsModule, // My Dialogs

    // NotFoundModule,
    // UserModule,
    StorageModule.forRoot({ IDBNoWrap: true }),
    ToastNoAnimationModule.forRoot(),

    // NbEvaIconsModule,
  ],
  exports: [RouterModule],
  providers: [
    { provide: APP_ID, useValue: 'myExclusiveVillas' },

    WINDOW_PROVIDERS,

    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },

    provideHttpClient(withInterceptors([progressInterceptor])),
    provideNgProgressRouter({
      startEvents: [GuardsCheckEnd],
      completeEvents: [NavigationEnd],
      minDuration: 300,
    }),
    provideClientHydration(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  prevUrl = '';
  pos = [0, 0];

  constructor(
    router: Router,
    viewportScroller: ViewportScroller,
    @Inject(DOCUMENT) private _document: Document,
    @Inject(PLATFORM_ID) private _platformId: Object
  ) {
    router.events
      .pipe(filter((e) => e instanceof Scroll))
      .subscribe((e: any) => {
        this.pos = viewportScroller.getScrollPosition();

        if (e.anchor) {
          // anchor navigation
          /* setTimeout is the core line to solve the solution */
          if (isPlatformBrowser(this._platformId)) {
            viewportScroller.scrollToAnchor(e.anchor);
            setTimeout(() => {
              viewportScroller.scrollToAnchor(e.anchor);
            });
          }
        } else if (e.position) {
          if (isPlatformBrowser(this._platformId)) {
            // backward navigation
            window.scrollTo(e.position);
          }
        } else {
          if (this.prevUrl.split('?')[0] !== e.routerEvent.url.split('?')[0]) {
            // viewportScroller.scrollToPosition([0, 0]);
            document.documentElement.style.scrollBehavior = 'auto';
            if (isPlatformBrowser(this._platformId)) {
              setTimeout(() => window.scrollTo(0, 0), 5);
              setTimeout(
                () =>
                  (document.documentElement.style.scrollBehavior = 'smooth'),
                4
              );
            }
          } else {
          }
        }
        this.prevUrl = e.routerEvent.url;
      });
  }
}
/**
 * O theos
 */
