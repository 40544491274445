<div class="d-block modal" @zoomIn @zoomOut>
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen-sm-down">
    <div class="modal-content shadow-lg">
      <div class="modal-header bg-primary text-white">
        <h5 class="modal-title">Sign Up</h5>
        <button type="button" class="btn-close text-white" data-dismiss="modal" aria-label="Close"
          (click)="dismiss('fromX')"></button>
      </div>
      <div class="modal-body">
        <div class="alert alert-{{ alert.type }}" role="alert" *ngIf="alert.message != '' "
          [innerHtml]="alert.message | safe : 'html'"></div>

        <ng-container [ngSwitch]="currentStepIndex">
          <app-account-info *ngSwitchCase="0" onSuccessfulSubmit="onSuccessfulSignupSubmit($event)"
            (stepValidity)="onSignUpFormStepValidityChange($event)"></app-account-info>

          <app-verify-email *ngSwitchCase="1" [verifyToken]="verifyToken"
            (stepValidity)="onVerifyMobileFormValidityChange($event)"></app-verify-email>

          <app-successful-signup *ngSwitchCase="2">

          </app-successful-signup>
        </ng-container>


        <div class="py-4" *ngIf="showFinished">
          Succesfuly Registered.
          <button class="btn btn-link" (click)="openLogin();">
            Click Here to Login
          </button>

        </div>
      </div>
      <div class="modal-footer mt-4">

        <button *ngIf="!isFirstStep && !isLastStep" class="btn btn-secondary" (click)="previousStep()"
          [disabled]="isFirstStep">Back</button>

        <button *ngIf="!isLastStep" class="btn btn-primary" (click)="nextStep()"
          [disabled]="!steps[currentStepIndex].isValid">
          {{ steps[this.currentStepIndex].nextButtonLabel }}
        </button>

        <button *ngIf="isLastStep" class="btn btn-success" (click)="dismiss('success')" [disabled]="false">
          <i class="fa fa-heart"></i> Done!
        </button>

        <!-- 
        <button type="submit" class="btn btn-lg btn-primary" autofocus [disabled]="invalidFieldsExist()">
          Sign Up
        </button>

        <a class="btn btn-lg btn-outline-danger" (click)="dismiss('fromX')">
          Cancel
        </a> -->
      </div>
    </div>
  </div>
</div>