import {
  animate,
  keyframes,
  style,
  transition,
  trigger,
} from '@angular/animations';

// -----------------------------------------------------------------------------------------------------
// @ Expand / collapse
// -----------------------------------------------------------------------------------------------------

const valueChanged = trigger('valueChanged', [
  transition(':increment', [
    style({ color: 'green', fontSize: '50px' }),
    animate('1.8s ease-out', style('*')),
  ]),
  transition(':decrement', [
    style({ color: 'red', fontSize: '50px' }),
    animate('1.8s ease-out', style('*')),
  ]),
]);

const fadeInOut = trigger('fadeInOut', [
  transition('void => *', [
    style({ opacity: '0', transform: 'translateX(-10%)' }),
    animate(750),
  ]),
  transition('* => void', [animate(750, style({ opacity: '0' }))]),
  transition('* => *', [
    style({ opacity: '0', transform: 'translateX(-10%)' }),
    animate(750, style({ opacity: '1', transform: 'translateX(0%)' })),
  ]),
]);

const fadeInOutFast = trigger('fadeInOutFast', [
  transition('void => *', [
    style({ opacity: '0', transform: 'translateX(-10%)' }),
    animate(250),
  ]),
  transition('* => void', [animate(250, style({ opacity: '0' }))]),
  transition('* => *', [
    style({ opacity: '0', transform: 'translateX(-10%)' }),
    animate(250, style({ opacity: '1', transform: 'translateX(0%)' })),
  ]),
]);

export { fadeInOut, valueChanged, fadeInOutFast };
