import { isPlatformBrowser } from '@angular/common';
import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  OnInit,
  Output,
  PLATFORM_ID,
} from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';
const loader = new Loader({
  apiKey: 'AIzaSyDAm9hZxPg_Y4vyKl9je2kAiSda9GGkz7Q',
  libraries: ['places', 'maps', 'marker'],
});

@Directive({
  selector: '[appMxvGautocomplete]',
  standalone: false,
})
export class MxvGautocompleteDirective implements OnInit {
  @Output() placeSelected: EventEmitter<any> = new EventEmitter();
  perviousKey = '';
  constructor(
    @Inject(PLATFORM_ID) private _platformId: Object,
    private _el: ElementRef
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this._platformId)) {
      loader
        .importLibrary('maps')
        .then(({ Map }) => {
          const autocomplete = new google.maps.places.Autocomplete(
            this._el.nativeElement,
            {
              fields: [
                'address_components',
                'geometry',
                'icon',
                'name',
                'place_id',
              ],
              componentRestrictions: { country: 'cy' },
            }
          );
          google.maps.event.addListener(autocomplete, 'place_changed', () => {
            const place = autocomplete.getPlace();
            this.placeSelected.emit(place);
            // this.placesBoxChanged.next(place);
          });
        })
        .catch((e) => {});
    }
  }
  @HostListener('keydown', ['$event'])
  onKeydown($event) {
    if ($event.key !== 'Enter') {
      this.perviousKey = $event.key;
    }
    if (
      $event.key === 'Enter' &&
      (this.perviousKey === 'ArrowDown' || this.perviousKey === 'ArrowUp')
    ) {
      $event.preventDefault();
      this.perviousKey = '';
    }
  }
}
