import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { mxvAnimations } from 'src/@tolis/animations';
import { IsoCountry } from 'src/app/models/iso-country';
import {
  AuthenticationService,
  LoginData,
} from 'src/app/services/authentication.service';
import { DIALOG_DATA } from '../tolis-dialog/tolis-dialog-tokens';
import { TolisDialogRef } from '../tolis-dialog/tolis-dialogref';

import { SignupService } from './signup.service';
import { TolisDialogService } from '../tolis-dialog/tolis-dialog.service';
import { LoginComponent, LoginError } from '../login/login.component';
import { WizardStep } from 'src/app/models/wizardstep';
import { TokenService } from 'src/app/services/token.service';
import { AuthcheckService } from 'src/app/services/authcheck.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'signup-dialog',
  templateUrl: './signup.dialog.html',
  styleUrls: ['./signup.dialog.css'],
  encapsulation: ViewEncapsulation.None,
  // changeDetection: ChangeDetectionStrategy.OnPush,
  animations: mxvAnimations,
  standalone: false,
})
export class SignupDialog implements OnInit {
  @ViewChild('countryField') private _countryField: ElementRef;

  verifyToken: string = '';
  signUpFormValues: any;
  verifyFormValues: any;

  _unsubscribeAll: Subject<void> = new Subject();

  showRegisterForm: boolean = true;
  showVerifyCodeForm: boolean = false;
  showFinished: boolean = false;

  isoCountries: IsoCountry[];
  formError: any = {};
  // to show spinning
  working = false;
  showAlert = false;
  alert = {
    type: 'danger',
    message: '',
  };
  verifyCodeAlert = {
    type: '',
    message: '',
  };

  steps: WizardStep[] = [
    {
      id: 'accountInfo',
      label: 'Account Information',
      isValid: true,
      nextButtonLabel: 'Submit',
    },
    {
      id: 'verifyEmail',
      label: 'Verify Email Address',
      isValid: false,
      nextButtonLabel: 'Verify',
    },
    {
      id: 'succesfullSignUp',
      label: 'Succesfuly Registered',
      isValid: false,
      nextButtonLabel: 'Close',
    },
  ];
  currentStepIndex = 0;

  constructor(
    private _tokenService: TokenService,
    private _authCheckService: AuthcheckService,
    private _authService: AuthenticationService,
    private _signupService: SignupService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _dialogRef: TolisDialogRef,
    private _dialogService: TolisDialogService,
    @Inject(DIALOG_DATA) public data: any,
    private elementRef: ElementRef,
    private _toastr: ToastrService
  ) {}

  ngOnInit(): void {
    // Get the country telephone codes
    this._signupService.getCountries().subscribe();
    this._signupService.countries$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((codes: IsoCountry[]) => {
        this.isoCountries = codes;
        // Mark for check...
        // this._changeDetectorRef.markForCheck();
      });
  }

  dismiss(how: string) {
    this._dialogRef.close(how);
    //this.close();
  }

  openLogin(): void {
    this.dismiss('success');
    const tolisDialogRef = this._dialogService.open(LoginComponent, {
      data: {
        selectedProducts: [],
        multiple: true,
      },
    });
  }

  submitSignUpForm(formValues): void {
    // Hide the alert
    this.showAlert = true;
    // Sign up
    this.alert = {
      type: 'info',
      message: 'Signing Up - Please wait ...',
    };

    this._authService.signUp(formValues).subscribe({
      next: (response) => {
        this.alert.message = '';

        this.verifyToken = response.v;
        this.currentStepIndex++;
      },
      error: (err) => {
        let message = '';
        Object.keys(err.error).forEach((_message, index) => {
          message += `<p>${err.error[_message]}</p>`;
        });

        this.alert = {
          type: 'danger',
          message: message,
        };
        this.showAlert = true;
        this._changeDetectorRef.markForCheck();
      },
    });
  }

  submitVerificationCode(formValues): void {
    this.alert = {
      type: 'info',
      message: 'Checking Verification Code - Please wait ...',
    };
    this._authService.verifyCode(formValues).subscribe({
      next: (response) => {
        this._toastr.success(
          'You succesfully Signed-Up. Thank you for becoming a member of myexclusivevillas.com',
          'Success'
        );
        this.handleSignIn(response);
        this.currentStepIndex++;
      },
      error: (err) => {
        this.alert = {
          type: 'danger',
          message: err.error.message,
        };
        this._changeDetectorRef.markForCheck();
      },
    });
  }

  get isLastStep(): boolean {
    return this.currentStepIndex === this.steps.length - 1;
  }

  get isFirstStep(): boolean {
    return this.currentStepIndex === 0;
  }

  nextStep(): void {
    if (this.steps[this.currentStepIndex].isValid) {
      if (this.currentStepIndex === 0) {
        this.submitSignUpForm(this.signUpFormValues);
      }
      if (this.currentStepIndex === 1) {
        this.submitVerificationCode(this.verifyFormValues);
      }
      if (this.currentStepIndex === 2) {
        this.dismiss('success');
      }
    }
  }

  previousStep(): void {
    if (this.currentStepIndex > 0) {
      this.currentStepIndex--;
    }
  }

  onSignUpFormStepValidityChange(result): void {
    this.signUpFormValues = result.formValues;
    this.verifyToken = result.submitResult;
    this.steps[this.currentStepIndex].isValid = result.isValid;
  }
  onVerifyMobileFormValidityChange(result): void {
    this.verifyFormValues = result.formValues;
    this.steps[this.currentStepIndex].isValid = result.isValid;
  }

  handleSignIn(loginData: LoginData) {
    if (this._tokenService.handle(loginData.access_token)) {
      setTimeout(() => {
        this._authCheckService.changeAuthStatus(true);
        this._changeDetectorRef.markForCheck();
      });
    }
  }

  handleError(error: LoginError): void {
    this._changeDetectorRef.markForCheck();
  }
}
